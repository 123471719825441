<template>
  <div class="container">
    <div class="screen clearfix">
      <div class="item">
        <span>字典名称</span>
        <div class="box">
          <el-input v-model="screen.dict_name" placeholder="输入字典名称" style="width: 240px"></el-input>
        </div>
      </div>
      <div class="item">
        <span>字典编码</span>
        <div class="box">
          <el-input v-model="screen.dict_code" placeholder="输入字典编码" style="width: 240px"></el-input>
        </div>
      </div>
      <span class="button inquire" @click="search">查询</span>
      <span class="button" @click="resetQuery">重置</span>
    </div>
    <el-table
        ref="multipleTable"
        :data="tableData.data"
        :border="true"
        v-loading="tableData.loading"
        style="width: 100%"
        @selection-change="handleSelectionChange">
      <el-table-column
          type="selection"
          width="60"
          align="center">
      </el-table-column>
      <el-table-column
          prop="dict_name"
          label="字典名称"
          min-width="100%">
      </el-table-column>
      <el-table-column
          prop="dict_code"
          label="字典编码">
      </el-table-column>
      <el-table-column
          prop="remark"
          label="字典描述">
      </el-table-column>
      <el-table-column
          prop="create_time"
          :formatter="row => parseTime(row.create_time)"
          label="创建时间">
      </el-table-column>
      <el-table-column
          prop="update_time"
          :formatter="row => parseTime(row.update_time)"
          label="更新时间">
      </el-table-column>
      <el-table-column
          label="操作"
          width="120">
        <template slot-scope="scope">
          <span class="handle" @click="toConfig(scope.row)">配置</span>
          <span class="handle" @click="deleteData(scope.row.id)">删除</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="operate">
      <div class="handle">
        <div class="all-select">
          <el-checkbox :indeterminate="tableData.isIndeterminate" v-model="tableData.allSelect"
                       @change="toggleSelection()"></el-checkbox>
        </div>
        <div class="button" @click="deleteData()">批量删除</div>
      </div>
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="screen.page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="screen.limit"
          layout="prev, pager, next, sizes"
          :total="tableData.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
const defaultQuery = {
  page: 1, // 请求页码
  limit: 10, // 每页显示条数
  dict_name: '',
  dict_code: '',
}

export default {
  name: 'Dict',
  data() {
    return {
      screen: {
        ...defaultQuery
      }, // 筛选条件
      tableData: {
        data: [], // 表格列表数据
        isIndeterminate: false, // 表格是否有选中但未全选
        allSelect: false, // 表格全选
        multipleSelection: [], // 表格选中列表
        currentPage: 1, // 表格当前页码
        total: 0, // 总条数
        loading: false, // 表格渲染loading
        ids: [], // 表格选中列表id值
      }, // 表格数据
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    handleSelectionChange(val) {
      this.tableData.multipleSelection = val;
      this.tableData.ids = val.map(item => item.id);
      this.tableData.isIndeterminate = val.length > 0 && val.length < this.tableData.data.length;
      this.tableData.allSelect = val.length === this.tableData.data.length ? true : false;
    }, // 表格选中改变
    toggleSelection() {
      this.$refs.multipleTable.toggleAllSelection();
    }, // 表格全选
    handleSizeChange(val) {
      this.screen.limit = val;
      this.getDataList();
    }, // 分页pageSize改变
    handleCurrentChange(val) {
      this.screen.page = val;
      this.getDataList();
    }, // 分页currentPage改变
    /**
     * 查询
     */
    search() {
      this.getDataList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.screen = {
        ...defaultQuery
      }
      this.search();
    },
    /**
     * 获取字典列表数据
     */
    getDataList() {
      this.tableData.loading = true;
      this.$api.dictList(this.screen).then(res => {
        this.$set(this.tableData, 'data', res.data.data);
        this.$set(this.tableData, 'total', res.data.count);
      }).finally(() => {
        this.tableData.loading = false;
      })
    },
    /**
     * 列表数据删除
     */
    deleteData(id) {
      if (!id && this.tableData.ids.length === 0) {
        this.$message({
          type: 'info',
          message: '请选择需要删除的数据！'
        });
        return false;
      }
      let ids = id ? id : this.tableData.ids;

      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.delDict({id: ids}).then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.resetQuery()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },
    /**
     * 跳转配置页面
     * @param row
     */
    toConfig(row) {
      let id = row?.id
      this.$router.push({name: 'DetailDict', query: {id: id}})
    },
  }
}
</script>

<style lang="scss" scoped src="@/styles/common.scss"></style>
<style lang="scss" scoped>
.container {
  margin: 18px;
  padding: 30px 20px;
  width: calc(100% - 76px);
  height: calc(100% - 96px);
  background: #FFFFFF;
  overflow: scroll;
  scrollbar-width: none;
}
</style>
